import { toggleMenuAtom } from "@/state/atoms"
import { Box, IconButton } from "@chakra-ui/react"
import { FaBars, FaHamburger } from "react-icons/fa"
import { useRecoilState } from "recoil"

const HamburgerButton = () => {

    const [isOpen, toggleMenu] = useRecoilState(toggleMenuAtom)
    return <>
        <Box paddingTop={'27px'} paddingRight={'10px'} paddingLeft={'20px'} display={{ base: 'block', md: 'block', lg: 'none' }}>
            <IconButton aria-label="abrir menu" icon={<FaBars />} fontSize={30} onClick={()=>toggleMenu(!isOpen)} />
        </Box>
    </>
}

export default HamburgerButton