'use client'

import { Button } from "@chakra-ui/react"
import Link from "next/link"

//const CustonButton = ({ as = 'a', padding='16px 50px 17px', href, children }: { as?: string, padding?: string, href?: string, children?: ReactNode }) => {
const CustonButton = (props: any) => {
    const buttonAfter = {
        content: '""',
        position: 'absolute',
        height: '0%',
        left: '50%',
        top: '50%',
        width: '150%',
        zIndex: '-1',
        background: '#000',
        transition: 'all 0.3s ease;',
        transform: 'translateX(-50%) translateY(-50%) rotate(-25deg)',
        boxSizing: 'border-box'
    }
    const getButton = () => {
        return (
            <Button
                as={props.as || 'a'}
                display={'inline-block'}
                verticalAlign={'middle'}
                border={'none'}
                outline={'none!important'}
                backgroundColor={'#cc0007'}
                fontSize={'16px'}
                fontWeight={800}
                padding={props.padding || '16px 50px 17px'}
                transition={'500ms'}
                overflow={'hidden'}
                zIndex={2}
                position={'relative'}
                borderRadius={0}
                height={''}
                color={'#fff'}
                lineHeight={1.875}
                _after={buttonAfter}
                _hover={{ color: '#cc0007', _after: { height: '450%', transition: 'all 1s ease 0s', color: '#cc0007', background: '#000' } }}
                {...props}>
                {props.children}
            </Button>
        )
    }
    if(props.type && props.type === 'submit') {
        return getButton()
    }
    return (
        <Link prefetch href={props.href || '/'} passHref legacyBehavior>
            {getButton()}
        </Link>
    )

}

export default CustonButton