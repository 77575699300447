'use client'
import React, { memo } from 'react'
import { Menu, MenuButton, MenuList, MenuItem, Button, useDisclosure, Text, Box, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { FaCircle } from 'react-icons/fa'
import { useRouter } from 'next/navigation'

const Item = (props: any) => {
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { item: { attributes: item } } = props

  const navigate = (item:any) => {
      if(item.target === '_blank') {
        window.open(item.url, '_blank', 'noreferrer')
        return;
      }
      router.push(item.url)
  }
  return <>
    <Menu autoSelect={false} key={props?.item?.name} isOpen={isOpen} gutter={0} variant='menuBrasal'>
      <NextLink href={item?.url || '#'} target={item.target} passHref legacyBehavior prefetch={false} >
        <MenuButton
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
          fontWeight={'600'}
          paddingTop={'15px'}
          paddingX={'16px'}
          fontSize={{ lg: '15px', xl: 18 }}
          colorScheme={'white'}
          variant={'ghost'}
          height={'100%'}
          margin={0}
          alignItems={'start'}
          _focusVisible={{ outline: 0 }}
          display={'inline-flex'}
          as={Link}>
            {item?.title}
      </MenuButton>
      </NextLink>
      {item?.children?.data.length > 0 &&
        <MenuList p={2} onMouseEnter={onOpen} onMouseLeave={onClose} border={0} motionProps={{}} >
          {isOpen && <SubItem right={<FaCircle />} items={item?.children?.data} onOpen={onOpen} onClose={onClose} />}
        </MenuList>
      }
    </Menu>
  </>
}
const SubItem = (props: any) => {
  const { items } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const after = {
    backgroundColor: '#cc0007',
    position: 'absolute',
    right: '22px',
    top: 0,
    bottom: 0,
    margin: 'auto',
    width: '5px',
    height: '5px',
    borderRadius: '50px',
    content: '""',
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 500ms ease',
  }
  return (
    <>
      {items.map((item: any, idx: number) => {
        return <React.Fragment key={item?.id}>
          {item?.attributes?.children?.data.length == 0 &&
            <NextLink prefetch={false} href={item?.attributes?.url || '#'} passHref legacyBehavior >
              <MenuItem
                as={'a'}
                target={item?.attributes?.target}
                paddingRight={20}
                paddingY={3}
                height={'100%'}
                position={'relative'}
                mt={idx > 0 ? 2 : 0}
                fontWeight={'600'}
                fontSize={{ lg: '10px', xl: 15 }}
                color={'gray.600'}
                _focusVisible={{ outline: 0 }}
                _selected={{ bg: 'white' }}
                _hover={{ bg: 'white', color: 'black', _after: { visibility: 'visible', opacity: 1 } }}
                _after={after}>
                {item?.attributes?.title}
              </MenuItem>
            </NextLink>
          }
          {item?.attributes?.children?.data.length > 0 && <>

            <Menu gutter={0} autoSelect={false} variant={'menuBrasal'} isOpen={isOpen} placement='right-start'>
              <MenuButton 
                as={MenuItem}
                paddingRight={20}
                paddingY={3}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                fontWeight={'600'}
                paddingTop={'15px'}
                fontSize={{ lg: '15px', xl: 18 }}
                height={'100%'}
                margin={0}
                alignItems={'start'}
                _focusVisible={{ outline: 0 }}>
                {item?.attributes?.title}
              </MenuButton>
              <MenuList p={2} onMouseEnter={onOpen} onMouseLeave={onClose} border={0} motionProps={{}}>
                {isOpen && <SubItem items={item?.attributes?.children?.data} />}
              </MenuList>
            </Menu>
          </>}
        </React.Fragment>
      })}
    </>
  )
}
const _MenuComponent = (props: any) => {
  const { data: { items } } = props
  
  if(true) return <></>
  return (
    <>
      <Box paddingLeft={'20px'} paddingTop={'23px'} display={{ base: 'none', md: 'none', lg: 'block' }}>
        {items?.data.map((item: any, index: number) => {
          return (
            <Item item={item} key={`menu-item-${index}`} />
          )
        })}
      </Box>
    </>
  )
}
const MenuComponent = memo(function MenuComponent(props:any) {
  const { data: { items } } = props
  
  return (
    <>
      <Box paddingLeft={'20px'} paddingTop={'23px'} display={{ base: 'none', md: 'none', lg: 'block' }}>
        {items?.data.map((item: any, index: number) => {
          return (
            <Item item={item} key={`menu-item-${index}`} />
          )
        })}
      </Box>
    </>
  )
})
export default MenuComponent
