'use client'
import { Box, Flex, Spacer, Container } from '@chakra-ui/react'
import { isValidMotionProp, motion, useAnimate, useMotionValueEvent, useScroll } from 'framer-motion'

import ContatoComponent from '@/components/contato'
import SocialComponent from '@/components/social'
import LangComponent from '@/components/lang'
import WorkComponent from '@/components/work'
import LeftLogoComponent from '@/components/left-logo'
import MenuComponent from '@/components/menu'
import RighttLogoComponent from '@/components/right-logo'
import SearchModal from '../modal'
import SearchComponent from './search'
import { useEffect, useState } from 'react'
import { chakra, shouldForwardProp } from '@chakra-ui/react'
import HamburgerButton from './hamburger-button'

export default function HeaderScrollComponent(props: any) {
  const { cabecalho, config, menuPrincipal} = props.data
  const { localizations } = props
  const [scope, animate] = useAnimate()
  const { scrollY } = useScroll()
  const [isShow, setIsShow] = useState(false)

  

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > 150 && !isShow) {
      setIsShow(true)
      animate(scope.current, {
        translateY: 0,
        opacity: 1
      }, {
        ease: 'easeOut'
      })
    } else if (latest < 150 && isShow) {
      setIsShow(false)
      animate(scope.current, {
        translateY: -150,
        opacity: 0
      }, {
        ease: 'easeIn'
      })
    }

  })
  if (!cabecalho) return (<></>)
  return (
    <Box as={motion.div}
      ref={scope}
      zIndex={30000}
      w='100%'
      initial={{
        translateY: -150,
        opacity: 0,
        backgroundColor: '#f1f1f1',
        color: '#000',
        position: 'fixed',
        top: 0
      }}
      whileHover={{
        backgroundColor: '#fff'
      }}
      rounded={0}
      role="group"
      
    >
      <SearchModal />
      <Container 
        as={motion.div}
        mt={0}
        maxW={1300}
      >
        <Flex borderBottom={'1px solid rgba(0, 0, 0, .2)'} gap={2} h={'40px'}>
          <SocialComponent data={config?.data?.attributes?.RedesSociais} />
          <ContatoComponent data={cabecalho?.data?.attributes} config={config} />
          <Spacer />
          <LangComponent localizations={localizations}  blogCategories={props?.blogCategories} />
          <WorkComponent data={config?.data?.attributes?.TrabalheConosco} isHeaderScroll={true} />
        </Flex>
        <Flex>
          <LeftLogoComponent data={cabecalho?.data?.attributes?.LogoBrasalPositiva?.data?.attributes} isHeaderScroll={true} />
          <Spacer />
          <MenuComponent data={menuPrincipal} />
          <Spacer />
          <RighttLogoComponent data={cabecalho?.data?.attributes?.LogoInstitutoPositiva?.data?.attributes} isHeaderScroll={true} />
          <SearchComponent isHeaderScroll={true} />
          <HamburgerButton />
        </Flex>
      </Container>
    </Box>
  )
}
