'use client'
import { Box } from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'

const LeftLogoComponent = ({data, isHeaderScroll=false}:{data:any, isHeaderScroll?:boolean}) => {
  
  const getPropsLogoDefault = () => {
    if(isHeaderScroll) {
      return {display: 'block'}
    }
    return {
      _groupHover: { display:'block' },
      display: 'none'
    }
  }

  const getPropsLogoHover = () => {
    if(isHeaderScroll) {
      return {display: 'none'}
    }
    return {
      _groupHover: { display:'none' },
      display: 'block'
    }
  }


  return (
    <Box p={'27px 0'}>
      <Box {...getPropsLogoDefault()}>
        <Link prefetch={false} href={'/'}>
          <Image style={{ width: 144, height: 56 }} width={144} height={56} title={data?.caption} alt={data?.name} className="logo-portal" src={'https://dr7dcgej7t392.cloudfront.net/uploads/logo_brasal_5c60ec1d14.svg'} />
        </Link>
      </Box>
      <Box {...getPropsLogoHover()}>
        <Link prefetch={false} href={'/'}>
          <Image priority style={{ width: 144, height: 56 }} width={144} height={56} title={data?.caption} alt={data?.name} className="logo-portal" src={'https://dr7dcgej7t392.cloudfront.net/uploads/logo_brasal_w_053773a682.svg'} />
        </Link>
      </Box>
    </Box>
  )
}

export default LeftLogoComponent
