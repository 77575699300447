'use client'

import { toggleMenuAtom } from "@/state/atoms"
import { Drawer, DrawerContent, DrawerOverlay, HStack, Text, Image, CloseButton, Box, Link, useDisclosure, Collapse, Button, Icon, VStack } from "@chakra-ui/react"
import NextLink from "next/link"
import { memo } from "react"
import { FaChevronDown } from "react-icons/fa"
import { useRecoilState } from "recoil"

const SubItem = (props: any) => {
    const { data,localizations } = props
    const [,toggleMenu] = useRecoilState(toggleMenuAtom)
    const { isOpen, onToggle } = useDisclosure()
    const hasChildren = (item:any) => item?.attributes?.children?.data.length > 0

    return <>
        <Box as='ul' padding={'5px 4px 0 4px'} borderTop={'1px solid rgba(1,1,1, 0.1)'} listStyleType={'none'} >
            {
                data.map((item: any, index: number) => {
                    return (
                        <Box as={'li'} key={`menu-subitem-${index}`} paddingLeft={'1em'} _notLast={{ borderBottom: '1px solid rgba(1,1,1, 0.1)' }} textAlign={'match-parent'} boxSizing={'border-box'} display={'list-item'}>
                            <Link 
                                prefetch={false}
                                as={NextLink} 
                                onClick={() => {
                                    onToggle()
                                    !hasChildren(item) && toggleMenu(false)
                                }}
                                href={item?.attributes?.url || '#'} 
                                display={'flex'} 
                                _hover={{ textDecor: 'none' }} 
                                justifyContent={'space-between'} 
                                lineHeight={'30px'} 
                                color={'#656565'} 
                                fontSize={'14px'} 
                                fontWeight={500} 
                                height={46} 
                                alignItems={'center'} 
                                transition={'500ms'}>
                                {item?.attributes?.title}
                                {hasChildren(item) && <ChevronButton isOpen={isOpen} />}
                            </Link>
                            {hasChildren(item) &&
                                <Collapse in={isOpen}>
                                    <Box as='ul' padding={'5px 4px 0 4px'} listStyleType={'none'}>
                                        {isOpen && item?.attributes?.children?.data.map((item: any, index: number) => {
                                            return <Item key={`menu-item-${index}`} item={item} />
                                        })}
                                    </Box>
                                </Collapse>
                            }
                        </Box>
                    )
                })
            }
        </Box>
    </>
}

const ChevronButton = ({ isOpen }: { isOpen: boolean }) => {
    return <>
        <Box display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            color={'#fff'}
            w={30}
            h={30}
            bg={isOpen ? '#000000' : '#cc0007'}
            transition={'transform 500ms ease'}
            transform={`rotate(${isOpen ? '0' : '-90'}deg)`}>
            <FaChevronDown />
        </Box>
    </>
}

const Item = (props: any) => {
    const { item: { attributes: item } } = props
    const [,toggleMenu] = useRecoilState(toggleMenuAtom)

    const { isOpen, onToggle } = useDisclosure()
    const getColor = () => {
        if (!item?.items) {
            return '#656565'
        }
        return isOpen ? '#cc0007' : '#656565'
    }
    return <>
        <Box as={'li'} textAlign={'match-parent'} boxSizing={'border-box'} display={'list-item'} borderBottom={'1px solid rgba(1,1,1, 0.1)'}>
            <Link as={NextLink}
                href={item?.url}
                onClick={() => { 
                    onToggle()
                    !(item?.children?.data.length > 0) && toggleMenu(false)
                }}
                display={'flex'}
                _hover={{ textDecor: 'none' }}
                justifyContent={'space-between'}
                lineHeight={'30px'}
                color={getColor()}
                fontSize={'14px'}
                fontWeight={500}
                height={46}
                alignItems={'center'}
                transition={'500ms'}>
                {item?.title}
                {item?.children?.data.length > 0 && <ChevronButton isOpen={isOpen} />}
            </Link>
            {item?.children?.data.length > 0 && <Collapse in={isOpen}>
                {isOpen && <SubItem data={item?.children?.data} />}
            </Collapse>}
        </Box>
    </>
}

const _HeaderMobile = (props: any) => {

    const { data: { menuPrincipal: { items: { data: items } } } } = props


    const [isOpen, toggleMenu] = useRecoilState(toggleMenuAtom)
    return <>
        <Drawer placement={"left"} isOpen={isOpen} size={'xs'} onClose={() => toggleMenu(false)}>
            <DrawerOverlay bg={'rgba(255,255,255, 0.48)'}/>
            <DrawerContent bg={'#f1f1f1'} p={'30px 15px'} overflowY={'auto'}>
                <VStack>
                    <HStack mb={'40px'} position={'relative'} w={'100%'}>
                        <Link href={'https://www.brasal.com.br'} target="_blank">
                            <Image alt="" src={'/images/logo-w2.png'} w={140} />
                        </Link>
                        <CloseButton onClick={() => toggleMenu(!isOpen)} position={'absolute'} top={0} right={0} />
                    </HStack>
                    <Box display={'block'} w={'100%'}>
                        <Box as='ul' padding={'5px 4px 0 4px'} listStyleType={'none'}>
                            {items.map((item: any, index: number) => {
                                return <Item key={`menu-item-${index}`} item={item} />
                            })}
                        </Box>
                    </Box>
                    <Box display={'block'} w={'100%'} marginTop={30}>
                        <Link href={'https://www.brasal.com.br/instituto'} target="_blank">
                            <Image alt="" src={'/images/rightLogo.png'} w={110} />
                        </Link>
                    </Box>
                </VStack>

            </DrawerContent>

        </Drawer>
    </>
}

const HeaderMobile = memo(function HeaderMobile(props:any){
    const { data: { menuPrincipal: { items: { data: items } } } } = props


    const [isOpen, toggleMenu] = useRecoilState(toggleMenuAtom)
    return <>
        <Drawer placement={"left"} isOpen={isOpen} size={'xs'} onClose={() => toggleMenu(false)}>
            <DrawerOverlay bg={'rgba(255,255,255, 0.48)'}/>
            <DrawerContent bg={'#f1f1f1'} p={'30px 15px'} overflowY={'auto'}>
                <VStack>
                    <HStack mb={'40px'} position={'relative'} w={'100%'}>
                        <Link href={'https://www.brasal.com.br'} target="_blank">
                            <Image alt="" src={'/images/logo-w2.png'} w={140} />
                        </Link>
                        <CloseButton onClick={() => toggleMenu(!isOpen)} position={'absolute'} top={0} right={0} />
                    </HStack>
                    <Box display={'block'} w={'100%'}>
                        <Box as='ul' padding={'5px 4px 0 4px'} listStyleType={'none'}>
                            {items.map((item: any, index: number) => {
                                return <Item key={`menu-item-${index}`} item={item} />
                            })}
                        </Box>
                    </Box>
                    <Box display={'block'} w={'100%'} marginTop={30}>
                        <Link href={'https://www.brasal.com.br/instituto'} target="_blank">
                            <Image alt="" src={'/images/rightLogo.png'} w={110} />
                        </Link>
                    </Box>
                </VStack>

            </DrawerContent>

        </Drawer>
    </>
})
export default HeaderMobile