'use client'
import { Flex, Icon, Image, Text } from '@chakra-ui/react'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab} from '@fortawesome/free-brands-svg-icons'
library.add(fas)
// @ts-ignore
library.add(fab)

const SocialComponent = (props:any) => {

  const camelToDash = (str: string) => {
    return str.replace(/([A-Z])/g, function ($1) { return "-" + $1.toLowerCase(); });
  }

  const IconComponent = (item: any) => {
    //const iconName = require('@fortawesome/free-solid-svg-icons')
    const camelIcon = camelToDash(item.Icone)
    let iconName: any = camelIcon.split('-')
    // remove o primeiro item
    iconName.shift()
    // remove o segundo item
    iconName.shift()
    // remove o terceiro item
    if (iconName[0] == 'reg') iconName.shift()
    iconName = iconName.join('-')
    return iconName
  }

  return (
    <Flex gap={2} alignItems={'center'}>
      {props?.data.map((item:any) => {
        // Precisa pensar numa forma de carregar os icones dinamicamente
        //const Icon = item.Icon == 'facebook' ? FaFacebookF : item.Icon == 'youtube' ? FaYoutube : FaLinkedin
        const iconName = IconComponent(item)
        return (
          <Link target='_blank' href={item?.Link||'#'} key={item?.id} >
           <FontAwesomeIcon style={{ fontSize: 14 }} icon={["fab", iconName]}  size={'sm'} color={'#CCC'} fontWeight={400} />
          </Link>
        )
      }
    )}
    </Flex>
  )
}

export default SocialComponent
