'use client'

import { Box, Image, Link } from '@chakra-ui/react'
import { searchModalAtom } from '@/state/atoms'
import { useRecoilState } from 'recoil'

const SearchComponent = ({ isHeaderScroll = false }: { isHeaderScroll?: boolean }) => {
    const [isOpen, setIsOpen] = useRecoilState(searchModalAtom)
    const getPropsIconDefault = () => {
        if (isHeaderScroll) {
            return { display: 'block' }
        }
        return {
            _groupHover: { display: 'block' },
            display: 'none'
        }
    }

    const getPropsIconHover = () => {
        if (isHeaderScroll) {
            return { display: 'none' }
        }
        return {
            _groupHover: { display: 'none' },
            display: 'block'
        }
    }
    return (<>
        <Box paddingTop={'23px'}>
            <Link className="sep-busca" href='#' onClick={() => setIsOpen(true)} 
                display={'flex'} 
                pos={'relative'} 
                alignItems={'center'} 
                paddingLeft={'30px'} 
                marginLeft={'19px'} 
                h={'50px'} 
                borderLeft={'1px solid rgba(255, 255, 255, 0.5)'}
                _groupHover={{borderLeft: '1px solid rgba(0, 0, 0, 1)'}}>
                <Image htmlWidth="24" htmlHeight="24" {...getPropsIconDefault()}  src='https://dr7dcgej7t392.cloudfront.net/uploads/lupa_39bfa81d1e.webp' w={'24px'} alt='' className="ico-lupa" />
                <Image htmlWidth="24" htmlHeight="24" {...getPropsIconHover()} src='https://dr7dcgej7t392.cloudfront.net/uploads/lupa_white_b31980e6e8.webp' w={'24px'} alt='' className="ico-lupa" />
            </Link>
        </Box>
    </>)
}

export default SearchComponent