'use client'
import { Image, Box } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Link from 'next/link'

const RightLogoComponent = ({data, isHeaderScroll=false}:{data:any, isHeaderScroll?:boolean}) => {

  const getPropsLogoDefault = () => {
    if(isHeaderScroll) {
      return {display: 'block'}
    }
    return {
      _groupHover: { display:'block' },
      display: 'none'
    }
  }

  const getPropsLogoHover = () => {
    if(isHeaderScroll) {
      return {display: 'none'}
    }
    return {
      _groupHover: { display:'none' },
      display: 'block'
    }
  }
  
  return (
    <Box display={{base:'none', md: 'none', lg: 'flex'}} justifyContent={'center'} alignItems={'start'} paddingTop={'25px'} marginRight={'38px'}>
      <Box {...getPropsLogoDefault()}>
        <Link prefetch href={'https://www.brasal.com.br/instituto'} target="_blank">
          <Image style={{width:128,height:41}} width={128} height={41} title={data?.caption} alt={data?.name} src={'https://dr7dcgej7t392.cloudfront.net/uploads/logo_instituto_brasal_b_15d9405811.svg'} />
        </Link>
      </Box>
      <Box {...getPropsLogoHover()}>
        <Link href={'https://www.brasal.com.br/instituto'} target="_blank">
          <Image style={{width:128,height:41}} width={128} height={41} title={data?.caption} alt={data?.name} src={'https://dr7dcgej7t392.cloudfront.net/uploads/logo_instituto_brasal_w_f1acec29df.svg'} />
        </Link>
      </Box>
    </Box>
  )
}

export default RightLogoComponent
