'use client'
import setLocation from '@/location'
import { Button, HStack, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Image, Text, useDisclosure, Box } from '@chakra-ui/react'
import { useRef, useState } from 'react'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { getCookie, setCookie } from 'cookies-next'

const LangComponent = (props: any) => {
  const { localizations } = props
  const locale = getCookie('next-locale')||'pt-BR'

  const [isOpen, setIsOpen] = useState(false)
  const [langs] = useState([
    { flag: 'https://dr7dcgej7t392.cloudfront.net/uploads/br_flag_69b0ed12ff.webp', label: 'BR', locale: 'pt-BR' },
    { flag: 'https://dr7dcgej7t392.cloudfront.net/uploads/usa_flag_77f9dda2f7.webp', label: 'ENG', locale: 'en' },
  ])
  const [defaultLang, setDefaultLang] = useState(locale)

  const getSelected = () => langs.find((i: any) => i.locale === defaultLang)
  const getLangList = () => langs.filter((i: any) => i.locale !== defaultLang)

  const setLang = (lang: string, locale:string) => {
    setDefaultLang(locale)
    setCookie('next-locale', locale)
    setLocation(locale)
    const location = localizations?.data.find((i: any) => i?.attributes?.locale === locale)
    let category = ''
    
    if(props?.blogCategories && props?.blogCategories.data.length) {
      const find = props?.blogCategories.data[0].attributes.localizations.data.find((i: any) => i?.attributes?.locale === locale)
      category = find ? `blog/${find?.attributes?.Slug}/` : ''
    }

    if (location) {
      //console.log(`/idioma?locale='${locale}&slug=${category}${location?.attributes?.Slug}`)
      window.location.href = `/idioma?locale='${locale}&slug=${category}${location?.attributes?.Slug}`
    } else {
      window.location.href = `/idioma?locale='${locale}&slug=`
    }
  }  

  //console.log('localizations', props)
  return <>

    <Popover trigger='hover' onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)}>
      <PopoverTrigger>
        <HStack cursor={'pointer'}>
          <Image htmlWidth="16" htmlHeight="16" alt={''} src={getSelected()?.flag} w={'16px'} />
          <Text fontSize={'12px'}>{getSelected()?.label}</Text>
          {!isOpen && <FaChevronDown className="seta-img-lang" />}
          {isOpen && <FaChevronUp className="seta-img-lang" />}
        </HStack>
      </PopoverTrigger>
      <PopoverContent w={'auto'} borderRadius={0}>
        <PopoverArrow />
        <PopoverBody color={'#000'} p={'2px'}>
          <Box>
            {getLangList().map((lang: any, index: number) => (
              <Button key={'button_'+index} size={'xs'} onClick={() => setLang(lang.label,lang.locale)} borderRadius={0}>
                <HStack>
                  <Image htmlWidth="16" htmlHeight="16" alt='' src={lang.flag} w={'16px'} />
                  <Text fontSize={'12px'}>{lang.label}</Text>
                </HStack>
              </Button>
            ))}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  </>
}

export default LangComponent

