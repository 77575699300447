'use client'
import { HStack, Text, Image } from '@chakra-ui/react'
import Link from 'next/link'

const ContatoComponent = ({data, isHeaderScroll=false}:{data:any, isHeaderScroll?:boolean}) => {
  
  const getPropsIconDefault = () => {
    if(isHeaderScroll) {
      return {display: 'block'}
    }
    return {
      _groupHover: { display:'block' },
      display: 'none'
    }
  }

  const getPropsIconHover = () => {
    if(isHeaderScroll) {
      return {display: 'none'}
    }
    return {
      _groupHover: { display:'none' },
      display: 'block'
    }
  }
  
    return (
      <HStack>
        <Image htmlWidth="64" htmlHeight="64" alt={''} {...getPropsIconDefault()} src={'https://dr7dcgej7t392.cloudfront.net/uploads/user_icon_b_5f4cc7ac98.webp'} w={'15px'} opacity={.5} />
        <Image htmlWidth="64" htmlHeight="64" alt={''} {...getPropsIconHover()} src={'https://dr7dcgej7t392.cloudfront.net/uploads/user_icon_aa6b83cf2d.webp'} w={'15px'} />
        <Text as="span" textTransform={'uppercase'} fontSize={'12px'} className="txt-link-top-right" lineHeight={'1.5'} fontWeight={100}>
          <Link prefetch={false} href={data?.PaginaExt || data?.PaginaInt?.data?.attributes?.Slug || '#'}>
          {data?.TextoLink}
          </Link>
        </Text>
      </HStack>
    )
}

export default ContatoComponent
