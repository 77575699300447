'use client'

import { Modal, ModalOverlay, ModalBody, ModalContent, Button, Input, HStack, Image } from '@chakra-ui/react'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { searchModalAtom } from '@/state/atoms'
import CustonButton from '@/components/button'
import { useRouter } from 'next/navigation'

const SearchModal = (props:any) => {
    const [isOpen, setIsOpen] = useRecoilState(searchModalAtom)
    const [search, setSearch] = useState('')
    const router = useRouter()

    const goToBusca = () => {
        setIsOpen(false)
        router.push('/busca/'+search)
    }

    const toggleModal = (e:any) => {
        e.preventDefault()
        if (search.length > 2) {
            goToBusca()
        }
    }
    const handleKeyDown = (e:any) => {
        if(e.key === 'Enter') {
            goToBusca()
        }
    }

    return (
        <Modal onClose={()=>{setIsOpen(false)}} isOpen={isOpen} isCentered closeOnOverlayClick motionPreset='slideInTop'>
            
            <ModalOverlay bg={'whiteAlpha.700'} />
            <ModalContent shadow={'none'}>
                
                <HStack gap={0}>
                    <Input 
                        border={0} 
                        borderRadius={0} 
                        bg={'white'} 
                        fontSize={'16px'} 
                        w={'100%'} 
                        h={'68px'}
                        paddingY={'1px'}
                        paddingLeft={'30px'} 
                        value={search}
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setSearch(e.target.value)}
                        placeholder='Digite aqui' />
                    <CustonButton 
                        as={'button'} 
                        w={'68px!important'} 
                        h={68} p={0} 
                        display={'flex'} 
                        justifyContent={'center'} 
                        alignItems={'center'} cursor={'pointer'} 
                        padding={'0'}
                        onClick={toggleModal}
                        >
                        <Image alt={''} src="https://dr7dcgej7t392.cloudfront.net/uploads/lupa_white_b31980e6e8.webp" w={'24px'} />
                    </CustonButton>
                </HStack>
               
            </ModalContent>
        </Modal>
    )
}
export default SearchModal
