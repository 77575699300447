'use client'
import { HStack, Text } from '@chakra-ui/react'

const ContatoComponent = ({data, config=null}:{data:any, config: any}) => {
  
  return (
      <HStack alignItems={'center'} display={{base:'none', md: 'flex'}}>
        <Text  as="span" textTransform={'uppercase'} fontSize={'12px'} lineHeight={'1.5'} fontWeight={100}>
          {config?.data?.attributes?.TxtContatoHeader} {data?.Telefone}
        </Text>
      </HStack>
    )
}

export default ContatoComponent
