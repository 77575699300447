import {
    atom,
} from 'recoil';
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

const tokenAtom = atom({
    key:`tokenAtom`,
    default: undefined,
    effects_UNSTABLE: [persistAtom]
})

const searchModalAtom = atom({
    key:`searchModalAtom`,
    default: false
})

const toggleMenuAtom = atom({
    key:`toggleMenuAtom`,
    default: false
})

export { tokenAtom, searchModalAtom, toggleMenuAtom }
