'use client'
import { Flex, Spacer, Container, Box } from '@chakra-ui/react'
import { motion, isValidMotionProp } from 'framer-motion'
import ContatoComponent from '@/components/contato'
import SocialComponent from '@/components/social'
import LangComponent from '@/components/lang'
import WorkComponent from '@/components/work'
import LeftLogoComponent from '@/components/left-logo'
import MenuComponent from '@/components/menu'
import RighttLogoComponent from '@/components/right-logo'
import SearchModal from '../modal'
import SearchComponent from './search'
import { chakra, shouldForwardProp } from '@chakra-ui/react'
import HamburgerButton from './hamburger-button'

export default function HeaderInitComponent(props: any) {
  const { cabecalho, menuPrincipal, config} = props.data
  const { localizations } = props
  
  

  return (
    <Box
      as={motion.div}
      initial={{
        backgroundColor: 'rgba(0,0,0,0)',
        color: '#fff',
        position: 'absolute',
        top: 0,
      }}
      whileHover={{
        backgroundColor: '#fff',
        color: '#000',
      }}
      //@ts-ignore
      transition={{
        duration: 0.25
      }}
      w={'100%'}
      rounded={0}
      zIndex={30000}
      role='group'
    >
      <SearchModal />
      <Container 
        as={motion.div}
        mt={0}
        maxW={1300}
      >
        <Flex borderBottom={'1px solid rgba(255, 255, 255, .2)'} _groupHover={{borderBottom: '1px solid rgba(0,0,0,.2)'}} gap={2} h={'40px'} justifyContent={'start'}>
          <SocialComponent data={config?.data?.attributes?.RedesSociais} />
          <ContatoComponent data={cabecalho?.data?.attributes} config={config} />
          <Spacer />
          <LangComponent localizations={localizations} blogCategories={props?.blogCategories} />
          <WorkComponent data={config?.data?.attributes?.TrabalheConosco} />
        </Flex>
        <Flex>
          <LeftLogoComponent data={cabecalho?.data?.attributes?.LogoBrasalPositiva?.data?.attributes} />
          <Spacer />
          <MenuComponent data={menuPrincipal} />
          <Spacer />
          <RighttLogoComponent data={cabecalho?.data?.attributes?.LogoInstitutoPositiva?.data?.attributes} />
          <SearchComponent />
          <HamburgerButton />
        </Flex>
      </Container>
    </Box>
  )
}
